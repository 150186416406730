@import url(https://fast.fonts.net/lt/1.css?apiType=css&c=bbfe319c-1319-4ed3-88a6-6904534bc96d&fontids=793308,793320);
@font-face {
  font-family: 'Camphor';
  src: url('/fonts/793308/1a75b1be-660a-4bbb-89eb-ac51af5dd23c.eot?#iefix');
  src: url('/fonts/793308/1a75b1be-660a-4bbb-89eb-ac51af5dd23c.eot?#iefix') format('eot'),
    url('/fonts/793308/7afb413d-536f-439c-bcca-ba53e24cda07.woff2') format('woff2'),
    url('/fonts/793308/4eea462d-03c9-489a-a928-185525ee60b0.woff') format('woff'),
    url('/fonts/793308/b2be4b07-b8c4-4d15-beaa-8e29cc0d9b5c.ttf') format('truetype');
}
@font-face {
  font-family: 'Camphor';
  font-weight: 700;
  src: url('/fonts/793320/2dfbc407-f527-4d2b-82c8-5d38905b6741.eot?#iefix');
  src: url('/fonts/793320/2dfbc407-f527-4d2b-82c8-5d38905b6741.eot?#iefix') format('eot'),
    url('/fonts/793320/30a72170-8872-4260-8db3-dc070ad9d5ac.woff2') format('woff2'),
    url('/fonts/793320/f4e7a76f-8831-4641-87b8-d45d17b148cc.woff') format('woff'),
    url('/fonts/793320/7bfa40d6-635d-4d32-9ce5-4b58a1332d0d.ttf') format('truetype');
}

body {
  margin: 0;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #357d45;
  background-color: var(--primary);
  font-family: 'Camphor', 'Open Sans', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  overflow: hidden;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}
/**
  Reset styling for small devices  
*/
@media (max-width: 1279px){
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  html {
    box-sizing : border-box;
  }
  h1, h2, h3, h4, h5, h6, p {
    margin:0;
  }
}
:root {
  --primary: #357d45;
  --primary-00: rgba(0, 94, 255, 0);
  --primary-04: rgba(0, 94, 255, 0.4);
  --primary-004: rgba(0, 94, 255, 0.04);
  --primary-light: #edf4ff;
  --primary-dark: #2a6437;
  --primary-border: rgba(0, 94, 255, 0.25);
  --secondary: #0cc572;
  --secondary-light: #eafff3;
  --secondary-border: rgba(12, 197, 114, 0.25);
  --warning: #f6993f;
  --warning-light: #fff7ee;
  --error: #f02f2f;
  --error-light: #ffeded;
  --error-border: rgba(240, 47, 47, 0.25);
  --background: #f6f8fa;
  --background-dark: #f9fbff;
  --default-border: #d4ddeb;
  --default-border-two: rgba(212, 221, 235, 0.64);
  --white: #fff;
  --text-primary: #434b65;
  --text-secondary: #757a89;
  --text-light-secondary: #b8d2ff;
  --text-info: #52649e;
  --text-disabled: #cfd5de;
  --alice-blue: #f2f7ff;
  --independence: #444c66;
  --ghost-White: #fafcff;
  --white: #ffffff;
  --honeydew: #ddf5e8;
}

